<template>
    <b-modal :id="modalId" ref="modal" size="lg" centered no-close-on-backdrop no-close-on-esc>
        <template #modal-header>
            <h5>{{ item && item.id > 0 ? 'Edit part' :'Add part' }}</h5>
        </template>
        <b-overlay :show="isLoading">
            <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form @submit.prevent="passes(saveItem)" id="user-form" v-if="item">
                    <b-row class="mb-2">
                        <b-col cols="4" md="4" class="text-left">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Part number" invalid-feedback="Enter the part number">
                                    <b-form-input v-model="item.partNumber" autocomplete="false" placeholder="Part number"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="8" md="8" class="text-left">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Part name" invalid-feedback="Enter the part name">
                                    <b-form-input v-model="item.description" autocomplete="false" placeholder="Part name"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
        </b-overlay>
        <template #modal-footer>
            <div class="text-right">
                <b-button @click="cancelAction" class="mr-2" variant="default"><font-awesome-icon icon="ban"></font-awesome-icon> Cancel</b-button>
                <b-button type="submit" form="user-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Save</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { extend } from 'vee-validate';
import { itemSaveUrl, itemUrl } from '@routes';
import * as constants from '@constants';
import { required } from 'vee-validate/dist/rules';

extend('required', {
    ...required
});

export default {
    name: 'ItemForm',
    props: {
        id: Number
    },
    data() {
        return {
            modalId: 'item-form-modal',
            toastTitle: 'Parts',
            item: {
                id: 0,
                partNumber: '',
                description: ''
            },
            isLoading: false
        };
    },
    methods: {
        loadData() {
            this.isLoading = true;

            this.axios.get(itemUrl(this.id)).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.item = response.data.item;
                }

                this.$bvModal.show(this.modalId);
            }).catch(error => {
                console.error(error);

                this.$emit('reset-item-form-id');

                this.$bvToast.toast('There was an error getting the part data', {
                    title: this.toastTitle,
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        saveItem() {
            this.isLoading = true;

            this.axios.post(itemSaveUrl(), this.item).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'success'
                    });

                    this.$bvModal.hide(this.modalId);
                    this.$emit('reset-item-form-id');
                    this.$emit('refresh-item-table');
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                console.error(error);

                this.$bvToast.toast('There was an error saving the part', {
                    title: this.toastTitle,
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        cancelAction() {
            this.$bvModal.hide(this.modalId);
            this.$emit('reset-item-form-id');
        }
    },
    watch: {
        id() {
            if (this.id !== null) {
                if (this.id > 0) {
                    this.loadData();
                } else {
                    this.item = {
                        id: 0,
                        partNumber: '',
                        description: ''
                    };

                    this.$bvModal.show(this.modalId);
                }
            }
        }
    }
};
</script>