<template>
    <b-card>
        <b-row class="mb-2">
            <b-col md="3" class="my-1">
                <b-input-group size="sm" prepend="Show" append="rows">
                    <b-form-select v-model="items.itemsPerPage" id="perPageSelect" size="sm" :options="items.pageOptions"
                                   style="max-width: 5em"></b-form-select>
                </b-input-group>
            </b-col>
            <b-col class="text-right">
                <b-button variant="primary" @click="itemFormId = 0">
                    <font-awesome-icon icon="plus"></font-awesome-icon> Add part
                </b-button>
            </b-col>
        </b-row>
        <b-table :items="loadData" :fields="items.fields" :filter="items.filter" :current-page="items.currentPage"
                 :per-page="items.itemsPerPage" empty-text="No rows" empty-filtered-text="No matching rows found"
                 striped bordered hover small show-empty :responsive="true" :sort-by="items.sortBy" :sort-desc="items.sortDesc"
                 ref="itemsTable" class="text-left mb-0" @context-changed="tableContextChanged">
            <template #thead-top>
                <b-tr class="table-filters">
                    <b-th v-for="(field, index) in items.fields" :key="field.key"
                          :class="items.filters[index] && items.filters[index].value && typeof items.filters[index].value === 'string' ? 'non-empty' : ''">
                        <b-row v-if="items.filters[index]">
                            <b-col v-if="items.filters[index].type === 'STRING'">
                                <b-input :placeholder="field.label" v-model="items.filters[index].value" debounce="300"></b-input>
                            </b-col>
                            <b-col v-else-if="items.filters[index].type === 'Select'">
                                <b-select v-model="items.filters[index].value">
                                    <b-select-option value="">All</b-select-option>
                                    <b-select-option value="TRUE">Enabled</b-select-option>
                                    <b-select-option value="FALSE">Disabled</b-select-option>
                                </b-select>
                            </b-col>
                        </b-row>
                    </b-th>
                </b-tr>
            </template>

            <template #cell(enabled)="row">
                <b-badge variant="primary" class="p-1" v-if="row.item.enabled">Enabled</b-badge>
                <b-badge variant="warning" class="p-1" v-else>Disabled</b-badge>
            </template>

            <template #cell(actions)="row">
                <b-button-group size="sm">
                    <b-button size="sm" @click="enable(row.item)" v-b-tooltip.hover :title="row.item.enabled ? 'Disable' : 'Enable'" variant="outline-primary">
                        <font-awesome-icon :icon="row.item.enabled ? 'toggle-off' : 'toggle-on'"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" @click="itemFormId = row.item.id" v-b-tooltip.hover title="Edit" variant="outline-primary">
                        <font-awesome-icon icon="edit"></font-awesome-icon>
                    </b-button>
                </b-button-group>
            </template>

            <template #table-caption>
                <b-row class="no-gutters">
                    <b-col sm="5" md="6">
                        Showing {{ start }} to {{ end }} of {{ items.totalRows }} row<template v-if="items.totalRows !== 1">s</template>
                    </b-col>
                    <b-col sm="7" md="6" class="my-1">
                        <b-pagination v-model="items.currentPage" :total-rows="items.totalRows"
                                      :per-page="items.itemsPerPage" align="right" class="mb-0"></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-table>

        <PartForm :id="itemFormId" @reset-item-form-id="itemFormId = null" @refresh-item-table="$refs.itemsTable.refresh()"></PartForm>
    </b-card>
</template>

<script>
import tableStateMixin from '../../mixins/tableState';
import { itemListUrl, itemEnableUrl } from '@routes';
import * as constants from '@constants';
import PartForm from './Form.vue';

export default {
    components: {
        PartForm
    },
    mixins: [tableStateMixin],
    data() {
        return {
            items: {
                fields: [
                    { key: 'partNumber', label: 'Part number', sortable: true },
                    { key: 'description', label: 'Part name', sortable: true },
                    { key: 'enabled', label: 'Status', class: 'text-center', sortable: true },
                    { key: 'actions', label: 'Actions', class: 'text-center' }
                ],
                filters: [
                    { column: 'partNumber', type: 'STRING', value: '' },
                    { column: 'description', type: 'STRING', value: '' },
                    { column: 'enabled', type: 'Select', value: 'ALL' },
                    { column: '', type: 'NA', value: null }
                ],
                filter: '',
                isLoading: false,
                pageOptions: [25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'b.name',
                sortDesc: false
            },
            breadcrumb: {
                title: 'Parts',
                path: []
            },
            itemFormId: null,
            toastTitle: 'Parts'
        };
    },
    mounted() {
        this.$emit('update-breadcrumb', this.breadcrumb);
        this.restoreTableState(this.items, this.tableState);
    },
    methods: {
        normalizeText(text) {
            if (text) {
                return text.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u0301]/g, '').normalize();
            }

            return '';
        },
        loadData(ctx) {
            if (ctx.sortBy !== '') {
                let data = {
                    filters: this.items.filters.filter(filter => filter.value && typeof filter.value === 'string' && filter.value.trim() !== ''),
                    ...ctx
                };

                data.currentPage = (data.currentPage - 1) * data.perPage;

                return this.axios.post(itemListUrl(), data).then(response => {
                    if (response.data.code === constants.CODE_OK) {
                        this.items.totalRows = response.data.totalRows;
                        return response.data.items;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    console.error(error);

                    return [];
                });
            }
        },
        enable(item) {
            this.$swal({
                title: `Are you sure you want to change the status to ${item.enabled ? 'disabled' : 'enable'}?`,
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    this.axios.put(itemEnableUrl(item.id), {
                        enable: !item.enabled
                    }).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });

                            this.$refs.itemsTable.refresh();
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(() => {
                        this.$bvToast.toast('There was an error updating the data', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    });
                }
            });
        },
        tableContextChanged(context) {
            this.items.sortBy = context.sortBy;
            this.items.sortDesc = context.sortDesc;

            this.saveTableState(this.$route.name, this.items);
        }
    },
    watch: {
        'items.filters': {
            handler() {
                this.items.currentPage = 1;
                this.$refs.itemsTable.refresh();
                this.saveTableState(this.$route.name, this.items);
            },
            deep: true
        }
    },
    computed: {
        start: function() {
            return this.items.totalRows === 0 ? 0 : (this.items.currentPage - 1) * this.items.itemsPerPage + 1;
        },
        end: function() {
            let offset = (this.items.currentPage - 1) * this.items.itemsPerPage;

            return (this.items.totalRows - offset) < this.items.itemsPerPage ? this.items.totalRows : offset + this.items.itemsPerPage;
        }
    }
};
</script>